<template>
  <div class="p-4">
    <!-- Fee table -->
    <table class="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Fee Type</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 1</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 2</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 3</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 4</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Yearly Total</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <!-- Row for each student -->
        <template v-for="student in students" :key="student._id">
          <tr>
            <td class="py-2 text-sm text-right text-gray-600">Tuition Fee for {{ student.firstNameLegal }} {{ student.lastNameLegal }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(calculateBaseTuition(student, students)) }}</td>
          </tr>
          <!-- SACE Levy row if applicable -->
          <tr v-if="student.saceLevy1 || student.saceLevy2">
            <td class="py-2 pl-8 text-sm text-right text-gray-600">{{ student.saceLevy1 ? 'SACE Levy 1' : 'SACE Levy 2' }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(student.saceLevy1 ? 96705 : student.saceLevy2 ? 159600 : 0) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">0</td>
            <td class="py-2 text-right text-sm text-gray-600">0</td>
            <td class="py-2 text-right text-sm text-gray-600">0</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(student.saceLevy1 ? 96705 : student.saceLevy2 ? 159600 : 0) }}</td>
          </tr>
          <!-- Building Levy row if corporate -->
          <tr v-if="getStudentStatus(student, students) === 'Corporate'">
            <td class="py-2 pl-8 text-sm text-right text-gray-600">Building Levy</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(291520 / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(291520 / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(291520 / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(291520 / 4) }}</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(291520) }}</td>
          </tr>
          <!-- Little Explorer Fee row if applicable -->
          <tr v-if="student.littleExplorer">
            <td class="py-2 pl-8 text-sm text-right text-gray-600">Little Explorer Program</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(calculateLittleExplorerTermFee() * 4) }}</td>
          </tr>
          <!-- Staff Child Discount row if applicable -->
          <tr v-if="student.staffChild">
            <td class="py-2 pl-8 text-sm text-right text-gray-600">Staff Child Discount (50%)</td>
            <td class="py-2 text-right text-sm text-red-600">{{ formatCurrency(-(calculateBaseTuition(student, students) / 4 + (student.saceLevy1 ? 96705 : student.saceLevy2 ? 159600 : 0)) * 0.5) }}</td>
            <td class="py-2 text-right text-sm text-red-600">{{ formatCurrency(-(calculateBaseTuition(student, students) / 4) * 0.5) }}</td>
            <td class="py-2 text-right text-sm text-red-600">{{ formatCurrency(-(calculateBaseTuition(student, students) / 4) * 0.5) }}</td>
            <td class="py-2 text-right text-sm text-red-600">{{ formatCurrency(-(calculateBaseTuition(student, students) / 4) * 0.5) }}</td>
            <td class="py-2 text-right text-sm font-medium text-red-600">{{ formatCurrency(-(calculateBaseTuition(student, students) + (student.saceLevy1 ? 96705 : student.saceLevy2 ? 159600 : 0)) * 0.5) }}</td>
          </tr>
        </template>
        <!-- Family Total section -->
        <template v-if="students.length >= 4">
          <tr class="border-t-2 border-gray-400">
            <td class="py-2 text-right text-sm font-bold text-gray-900">Family Subtotal</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(1)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(2)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(3)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(4)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyYearlyTotal()) }}</td>
          </tr>
          <!-- Family Size Discount row -->
          <tr>
            <td class="py-2 text-right text-sm font-bold text-gray-600">Family Size Discount (15%)</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(1) * 0.15) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(2) * 0.15) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(3) * 0.15) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(4) * 0.15) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyYearlyTotal() * 0.15) }}</td>
          </tr>
        </template>
        <!-- Final Total row -->
        <tr class="border-t border-gray-300">
          <td class="py-2 text-right text-sm font-bold text-gray-900">Family Total</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(1)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(2)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(3)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(4)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyYearlyTotal()) }}</td>
        </tr>
        <!-- Invoice Generation row -->
        <tr>
          <td class="py-2 text-right text-sm font-semibold text-gray-900">Invoice Generation</td>
          <td v-for="term in [1, 2, 3, 4]" :key="term" class="py-2 text-right">
            <XeroInvoiceGenerator
              :family-code="familyCode"
              :term="term"
              :students="students"
              :term-total="calculateFinalFamilyTotal(term)"
              :invoice_id="students.find(s => s.Invoices && s.Invoices[`Term${term}${new Date().getFullYear()}Invoice`])?.Invoices[`Term${term}${new Date().getFullYear()}Invoice`]"
              :is-edit-mode="isEditMode"
              @invoice-generated="handleInvoiceGenerated"
              @invoice-voided="handleInvoiceVoided"
              class="text-xs inline-block"
            />
          </td>
          <td class="py-2 text-right">
            <button
              @click="isEditMode = !isEditMode"
              class="text-xs font-semibold rounded-md px-2 py-1 ring-1 ring-inset"
              :class="{
                'text-indigo-600 hover:text-indigo-500 ring-indigo-600/20 hover:bg-indigo-50': !isEditMode,
                'text-red-600 hover:text-red-500 ring-red-600/20 hover:bg-red-50': isEditMode
              }">
              {{ isEditMode ? 'Done' : 'Edit' }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// Exported utilities
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-VU', {
    style: 'currency',
    currency: 'VUV',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};

const getSchoolLevel = (year) => {
  if (['PRE'].includes(year)) {
    return 'EEC';
  }
  if (year === 'FND' || (year?.startsWith('Year ') && parseInt(year.replace('Year ', '')) <= 4)) {
    return 'Junior';
  }
  const yearNumber = parseInt(year?.replace('Year ', ''));
  if (yearNumber >= 5 && yearNumber <= 8) {
    return 'Middle';
  }
  if (yearNumber >= 9 && yearNumber <= 12) {
    return 'Senior';
  }
  return '';
};

const getStudentStatus = (student, allFamilyStudents = []) => {
  // If student is corporate, this takes precedence over everything
  if (student.debenturesCorp) {
    return 'Corporate';
  }
  
  // Check if any student in the family has Citizen/Long-Term status
  const hasLongTermInFamily = allFamilyStudents.some(familyStudent => {
    const startDate = new Date(familyStudent.firstAttendanceDate);
    const endDate = new Date();
    let years = 0;

    if (!isNaN(startDate.getTime())) {
      years = endDate.getFullYear() - startDate.getFullYear();
      if (endDate.getMonth() < startDate.getMonth() || 
          (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
        years--;
      }
    }

    return familyStudent.primaryCitizenship === 'Vanuatu' || years > 2;
  });

  // If any family member is Citizen/Long-Term, apply it to this student
  if (hasLongTermInFamily) {
    return 'Citizen/Long-Term';
  }
  

  // Individual student check (fallback if no family member is long-term)
  const startDate = new Date(student.firstAttendanceDate);
  const endDate = new Date();
  let years = 0;

  if (!isNaN(startDate.getTime())) {
    years = endDate.getFullYear() - startDate.getFullYear();
    if (endDate.getMonth() < startDate.getMonth() || 
        (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
      years--;
    }
  }
  
  if (student.primaryCitizenship === 'Vanuatu' || years > 2) {
    return 'Citizen/Long-Term';
  }
  
  return 'Non-Citizen';
};

const calculateBaseTuition = (student, allFamilyStudents) => {
  let fee = 0;
  const schoolLevel = getSchoolLevel(student.currentYear);
  const status = getStudentStatus(student, allFamilyStudents);

  // Base fee calculation based on school level and status
  if (schoolLevel === 'EEC') {
    // Only PRE students need to specify their fee component
    switch (student.feeComponent) {
      case 'Full ELP':
        fee = 653412;
        break;
      case 'Five Mornings':
        fee = 471720;
        break;
      case 'Three Mornings':
        fee = 356649;
        break;
    }
  } else {
    // For other school levels (Junior, Middle, Senior)
    switch (schoolLevel) {
      case 'Junior':
        fee = status === 'Corporate' ? 903207 : 
              status === 'Non-Citizen' ? 771207 : 
              663207; // Citizen/Long-Term
        break;
      case 'Middle':
        fee = status === 'Corporate' ? 936801 : 
              status === 'Non-Citizen' ? 804801 : 
              696801; // Citizen/Long-Term
        break;
      case 'Senior':
        fee = status === 'Corporate' ? 1001828 : 
              status === 'Non-Citizen' ? 869828 : 
              761828; // Citizen/Long-Term
        break;
    }
  }

  return Math.round(fee);
};

const calculateYearlyFee = (student, allFamilyStudents) => {
  const baseFee = calculateBaseTuition(student, allFamilyStudents);
  let totalFee = baseFee;
  
  // Add SACE levy to yearly total
  if (student.saceLevy1) {
    totalFee += 96705;
  } else if (student.saceLevy2) {
    totalFee += 159600;
  }

  // Add building levy for corporate students
  if (getStudentStatus(student, allFamilyStudents) === 'Corporate') {
    totalFee += 291520;
  }
  
  return Math.round(totalFee);
};

const calculateTermFee = (student, allFamilyStudents, term = 1) => {
  const baseFee = calculateBaseTuition(student, allFamilyStudents);
  let termTotal = baseFee / 4;
  
  // Add SACE levy only to term 1
  if (term === 1) {
    if (student.saceLevy1) {
      termTotal += 96705;
    } else if (student.saceLevy2) {
      termTotal += 159600;
    }
  }

  // Add building levy for corporate students (divided by 4 for per-term amount)
  if (getStudentStatus(student, allFamilyStudents) === 'Corporate') {
    termTotal += 291520 / 4;
  }
  
  return Math.round(termTotal);
};

// Add Little Explorer term fee calculation
const calculateLittleExplorerTermFee = () => {
  return 32455; // Already a whole number
};

// Add new function for staff child discount calculation
const calculateStaffChildDiscount = (student, allFamilyStudents = []) => {
  let termTotal = calculateTermFee(student, allFamilyStudents);
  
  // Add Little Explorer fee if applicable
  if (student.littleExplorer) {
    termTotal += calculateLittleExplorerTermFee();
  }

  // Calculate 50% discount
  return Math.round(termTotal * 0.5);
};

// Export all the functions
export {
  formatCurrency,
  getSchoolLevel,
  getStudentStatus,
  calculateBaseTuition,
  calculateYearlyFee,
  calculateTermFee,
  calculateLittleExplorerTermFee,
  calculateStaffChildDiscount
};
</script>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import XeroInvoiceGenerator from './XeroInvoiceGenerator.vue';

/**
 * @typedef {Object} Student
 * @property {string} _id
 * @property {string} firstNameLegal
 * @property {string} lastNameLegal
 * @property {string} currentYear
 * @property {string} feeComponent
 * @property {string} firstAttendanceDate
 * @property {string} primaryCitizenship
 * @property {boolean} debenturesCorp
 * @property {boolean} staffChild
 * @property {boolean} bursary
 * @property {string} status
 */

const props = defineProps({
  students: {
    type: Array,
    required: true
  },
  familyCode: {
    type: String,
    required: true
  },
  invoices: {
    type: Object,
    default: () => ({})
  },
  generatingInvoice: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['invoice-generated']);

const handleInvoiceGenerated = (data) => {
  emit('invoice-generated', data);
};

const calculateFamilyTotal = (term) => {
  return Math.round(props.students.reduce((total, student) => {
    // Base tuition per term
    let termTotal = calculateBaseTuition(student, props.students) / 4;
    
    // Add SACE Levy only in Term 1
    if (term === 1) {
      if (student.saceLevy1) {
        termTotal += 96705;
      } else if (student.saceLevy2) {
        termTotal += 159600;
      }
    }
    
    // Add building levy for corporate students (divided by 4 for per-term amount)
    if (getStudentStatus(student, props.students) === 'Corporate') {
      termTotal += 291520 / 4;
    }

    // Apply staff child discount if applicable (only to tuition)
    if (student.staffChild) {
      termTotal = termTotal * 0.5;
    }
    
    // Add Little Explorer fee if applicable (after staff discount)
    if (student.littleExplorer) {
      termTotal += calculateLittleExplorerTermFee();
    }

    return total + termTotal;
  }, 0));
};

const calculateFamilyYearlyTotal = () => {
  return Math.round(props.students.reduce((total, student) => {
    let yearlyTotal = calculateYearlyFee(student, props.students);
    
    // Add Little Explorer yearly fee if applicable
    if (student.littleExplorer) {
      yearlyTotal += calculateLittleExplorerTermFee() * 4;
    }

    // Apply staff child discount if applicable
    if (student.staffChild) {
      yearlyTotal = yearlyTotal * 0.5;
    }

    return total + yearlyTotal;
  }, 0));
};

const calculateFinalFamilyTotal = (term) => {
  const subtotal = calculateFamilyTotal(term);
  return Math.round(props.students.length >= 4 ? subtotal * 0.85 : subtotal);
};

const calculateFinalFamilyYearlyTotal = () => {
  const subtotal = calculateFamilyYearlyTotal();
  return Math.round(props.students.length >= 4 ? subtotal * 0.85 : subtotal);
};

const isEditMode = ref(false);

const handleInvoiceVoided = ({ term }) => {
  // Find the student with the invoice and remove it
  const student = props.students.find(s => s.Invoices && s.Invoices[`Term${term}${new Date().getFullYear()}Invoice`]);
  if (student && student.Invoices) {
    delete student.Invoices[`Term${term}${new Date().getFullYear()}Invoice`];
  }
};
</script> 