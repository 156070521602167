<template>
  <div class="bg-white pb-8 sm:pb-8">
    <div class="mx-auto max-w-2xl py-2">
      <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
    </div>

    <div class="mx-auto mt-8 max-w-7xl px-6 lg:px-8">
      <p class="text-left">Number of students potentially withdrawn: {{ potentialWithdrawnCount }}</p>
      <p class="text-left">Comparing local database with Wonde students...</p>

      <!-- Loading Animation -->
      <div v-if="isLoading" class="flex flex-col justify-center items-center h-screen">
        <div class="mb-4 text-xl font-bold">Loading...</div>
        <div class="loader"></div>
      </div>

      <!-- Students to Withdraw Table -->
      <div v-if="!isLoading" class="mt-8">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th class="text-left px-4 py-2">Legal Name</th>
              <th class="text-left px-4 py-2">Hero ID</th>
              <th class="text-left px-4 py-2">Family Code</th>
              <th class="text-left px-4 py-2">Current Status</th>
              <th class="text-left px-4 py-2">Reason</th>
              <th class="text-left px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="student in potentialWithdrawnStudents" :key="student.heroId">
              <td class="px-4 py-2">{{ student.firstNameLegal }} {{ student.lastNameLegal }}</td>
              <td class="px-4 py-2">{{ student.heroId }}</td>
              <td class="px-4 py-2">{{ student.familyCode }}</td>
              <td class="px-4 py-2">{{ student.status }}</td>
              <td class="px-4 py-2 text-sm text-gray-600">{{ student.withdrawReason }}</td>
              <td class="px-4 py-2">
                <button 
                  @click="markAsWithdrawn(student)"
                  class="rounded-md px-3 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  :class="{
                    'bg-red-600 hover:bg-red-500': !withdrawingStudents.has(student.heroId),
                    'bg-gray-400 cursor-wait': withdrawingStudents.has(student.heroId)
                  }"
                  :disabled="withdrawingStudents.has(student.heroId)"
                >
                  <span v-if="!withdrawingStudents.has(student.heroId)">Mark as Withdrawn</span>
                  <span v-else class="flex items-center">
                    <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </span>
                </button>
              </td>
            </tr>
            <tr v-if="potentialWithdrawnStudents.length === 0">
              <td colspan="5" class="px-4 py-4 text-center text-gray-500">
                No students found that need to be withdrawn.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import wondeService from '../../api/wondeService';

const apiUrl = import.meta.env?.VITE_API_URL || 
  (process.env.NODE_ENV === 'production' ? 
    'https://pvis.edu.vu' : 
    'http://localhost:3000');

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

const wondeStudents = ref([]);
const localStudents = ref([]);
const potentialWithdrawnStudents = ref([]);
const schoolId = ref('A1461335658');
const isLoading = ref(false);
const isDataLoaded = ref(false);
const withdrawingStudents = ref(new Set());

const potentialWithdrawnCount = computed(() => {
  return potentialWithdrawnStudents.value.length;
});

const markAsWithdrawn = async (student) => {
  try {
    withdrawingStudents.value.add(student.heroId);

    const response = await axios.post('/api/updateStudent', {
      _id: student._id,
      heroId: student.heroId,
      status: 'Withdrawn'
    });

    if (response.status === 200) {
      // Remove the student from the list
      potentialWithdrawnStudents.value = potentialWithdrawnStudents.value.filter(
        s => s.heroId !== student.heroId
      );
      alert('Student marked as withdrawn successfully!');
    }
  } catch (error) {
    console.error('Failed to mark student as withdrawn:', error);
    alert('Failed to mark student as withdrawn. Please try again.');
  } finally {
    withdrawingStudents.value.delete(student.heroId);
  }
};

// Fetch students from Wonde
const fetchWondeStudents = async () => {
  try {
    const response = await wondeService.getStudents(schoolId.value);
    wondeStudents.value = response.data;
  } catch (error) {
    console.error('Failed to fetch students from Wonde:', error);
    alert('Failed to fetch students from Wonde. Please check the console for details.');
  }
};

// Fetch students from local database
const fetchLocalStudents = async () => {
  try {
    const response = await axios.get('/api/getStudents');
    localStudents.value = response.data;
  } catch (error) {
    console.error('Failed to fetch local students:', error);
    alert('Failed to fetch local students. Please check the console for details.');
  }
};

// Compare and identify potential withdrawn students
const compareStudents = () => {
  // Get all currently enrolled students from local database
  const enrolledStudents = localStudents.value.filter(
    student => student.status === 'Enrolled'
  );

  // Create a map of Wonde students by their names for easier lookup
  const wondeStudentMap = new Map(
    wondeStudents.value.map(student => [
      `${student.legal_forename.toLowerCase()} ${student.legal_surname.toLowerCase()}`,
      student
    ])
  );

  // Find students who are in local DB but not in Wonde
  potentialWithdrawnStudents.value = enrolledStudents.map(localStudent => {
    const fullName = `${localStudent.firstNameLegal.toLowerCase()} ${localStudent.lastNameLegal.toLowerCase()}`;
    const isInWonde = wondeStudentMap.has(fullName);
    
    // Add withdrawal reason
    let withdrawReason = '';
    if (!isInWonde) {
      withdrawReason = 'Student not found in Wonde - may have left the school';
      
      // Check for similar names to catch potential spelling differences
      const similarNames = Array.from(wondeStudentMap.keys()).filter(wondeName => {
        const similarity = calculateNameSimilarity(fullName, wondeName);
        return similarity > 0.8; // 80% similarity threshold
      });
      
      if (similarNames.length > 0) {
        withdrawReason = 'Possible name mismatch in Wonde - please verify spelling';
      }
    }

    return {
      ...localStudent,
      withdrawReason
    };
  }).filter(student => student.withdrawReason); // Only keep students with a withdrawal reason
};

// Add a simple name similarity function
const calculateNameSimilarity = (name1, name2) => {
  const arr1 = name1.split('');
  const arr2 = name2.split('');
  let matches = 0;
  
  // Compare each character
  for (let i = 0; i < Math.min(arr1.length, arr2.length); i++) {
    if (arr1[i] === arr2[i]) matches++;
  }
  
  // Return similarity ratio
  return matches / Math.max(arr1.length, arr2.length);
};

onMounted(async () => {
  if (isDataLoaded.value) return;
  
  isLoading.value = true;
  try {
    await Promise.all([fetchWondeStudents(), fetchLocalStudents()]);
    compareStudents();
    isDataLoaded.value = true;
  } catch (error) {
    console.error('Error during initial data load:', error);
    alert('Failed to load student data. Please try again.');
  } finally {
    isLoading.value = false;
  }
});
</script>

<style>
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style> 